import { PUBLIC_AUTH_URL } from '$env/static/public';
import { ofetch } from 'ofetch';
interface Fetch {
	endpoint: string;
	method: string;
	body: object | undefined;
}
const DeprecatedFetch_useapiFetch = (request: Fetch): Promise<{ [key: string]: any }> => {
	const url = PUBLIC_AUTH_URL + request.endpoint;

	return new Promise((resolve, reject) => {
		fetch(url, {
			method: request.method,
			headers: {
				'Content-type': 'application/json'
			},
			credentials: 'include',
			body: request.body ? JSON.stringify(request.body) : undefined
		})
			.then((response) => {
				if (!response.ok) {
					throw new Error(response.statusText);
				}

				if (response.headers.get('Content-Type')?.startsWith('application/json')) {
					return response.json();
				} else {
					return response.text();
				}
			})
			.then((data: { [key: string]: any }) => {
				console.log(url, data);

				resolve(data);
			})
			.catch((e) => {
				reject(e);
			});
	});
};
export { DeprecatedFetch_useapiFetch };

const apiFetch = ofetch.create({
	baseURL: PUBLIC_AUTH_URL,
	onResponse: ({ request, response, options }) => {
		if (process.env.NODE_ENV === 'development') {
			// Log response
			// eslint-disable-next-line no-console -- Neccasary console log
			console.log('[Response Tracing]', { request }, response.status, { options }, response._data);
		}
	},
	headers: {
		'Content-Type': 'application/json'
	},
	credentials: 'include'
});

export { apiFetch };

export interface apiResponse {
	status: string;
	data: object;
	message: string;
	error: object;
}
